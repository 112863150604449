<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="!isDirty"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="scheduled-aip-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'SCHEDULED AIP NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <div v-if="showMainSection" id="scheduled-aip-main" class="collapsable-section">
          <h2 class="subheader">
            Build AIP
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBuildAIP ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="showBuildAIP = !showBuildAIP"
          />

          <div v-show="showBuildAIP" class="row">
            <VasionInput
              id="process-folder-name"
              v-model="processFolderName"
              class="row-field browse-input"
              title="PROCESS FOLDER"
              inputType="top-white"
              name="process-folder-name"
              :width="'306'"
            />
            <VasionButton
              id="browse"
              class="browse-button"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('processFolder')"
            >
              Browse
            </VasionButton>
            <VasionCheckbox
              id="include-subfolders"
              name="include-subfolders"
              class="export-checkbox"
              :checked="includeSubfolders"
              @change="toggleCheckbox('includeSubfolders'); isDirty = true"
            >
              Include Subfolders
            </VasionCheckbox>
          </div>
          <div v-show="showBuildAIP" class="row">
            <div>
              <label class="vasion-input-label-top">ON SUCCESS</label>
              <br>
              <div class="radio-buttons">
                <md-radio v-model="onSuccessAction" value="none" @change="isDirty = true">
                  None
                </md-radio>
                <md-radio v-model="onSuccessAction" value="sendToFolder" @change="isDirty = true">
                  Send to Folder
                </md-radio>
                <md-radio v-model="onSuccessAction" value="sendToWorkflow" @change="isDirty = true">
                  Send to Workflow
                </md-radio>
              </div>
            </div>
          </div>
          <div v-show="showBuildAIP" class="row">
            <VasionInput
              id="success-folder-name"
              v-model="successFolderName"
              class="row-field browse-input"
              :isDisabled="onSuccessAction !== 'sendToFolder'"
              title="SUCCESS FOLDER"
              inputType="top-white"
              name="success-folder-name"
              placeholder="Select Folder..."
              :readonly="true"
              :width="'306'"
            />
            <VasionButton
              id="browse"
              class="browse-button"
              :isDisabled="onSuccessAction !== 'sendToFolder'"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('successFolder')"
            >
              Browse
            </VasionButton>
            <VasionInput
              id="failure-folder"
              v-model="failureFolderName"
              class="row-field browse-input"
              :isDisabled="onSuccessAction !== 'sendToFolder'"
              title="FAILURE FOLDER"
              inputType="top-white"
              name="failure-folder"
              placeholder="Select Folder..."
              :readonly="true"
              :width="'306'"
            />
            <VasionButton
              id="browse-button"
              class="browse-button"
              :isDisabled="onSuccessAction !== 'sendToFolder'"
              :classProp="'primary'"
              @vasionButtonClicked="toggleBrowseFolderDialog('failureFolder')"
            >
              Browse
            </VasionButton>
            <VasionDropList
              v-slot="slotItem"
              v-model="workflow"
              class="row-field"
              :isDisabled="onSuccessAction !== 'sendToWorkflow'"
              :dataArray="workflowList"
              width="396"
              title="SUCCESS WORKFLOW"
              placeholder="Select Workflow..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-show="showBuildAIP && showTextract" class="row">
            <div>
              <label class="vasion-input-label-top">AIP TYPE</label>
              <br>
              <div class="radio-buttons">
                <md-radio v-model="aipType" :value="typeBasic" @change="aipTypeChanged()" title="You may select multiple AIP processes">
                  Vasion AIP
                </md-radio>
                <md-radio v-model="aipType" :value="typeTextract" @change="aipTypeChanged()" title="You may select only one AIP process">
                  Amazon Textract
                </md-radio>
              </div>
            </div>
          </div>
          <div v-show="showBuildAIP" class="row">
            <VasionDropList
              v-slot="slotItem"
              v-model="imageProcess"
              class="row-field"
              :dataArray="imageProcessList"
              width="306"
              title="ADD IMAGE PROCESS"
              placeholder="Search Image Process..."
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <VasionButton
              id="add-image-process"
              class="browse-button"
              :classProp="'secondary'"
              :isDisabled="!canAddThisImageProcess"
              @vasionButtonClicked="addImageProcess(); isDirty = true"
            >
              Add
            </VasionButton>

            <div class="arrow-buttons">
              <VasionButton
                id="field-up"
                name="field-up"
                class="step-button"
                :isDense="true"
                :icon="'VasionArrowUpIcon'"
                :isDisabled="imageProcesses.length === 0"
                title="Move Image Process Up"
                @vasionButtonClicked="moveImageProcessUp"
              />

              <VasionButton
                id="field-down"
                name="field-down"
                class="step-button"
                :isDense="true"
                :icon="'VasionArrowDownIcon'"
                :isDisabled="imageProcesses.length === 0"
                title="Move Image Process Down"
                @vasionButtonClicked="moveImageProcessDown"
              />
            </div>
          </div>
          <div v-show="showBuildAIP" class="row">
            <div class="grid-div vasion-html-table-default table-width">
              <table>
                <thead>
                  <tr>
                    <th>
                      <label class="vasion-html-table-header">Image Processes</label>
                    </th>
                    <th class="image-column-end" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(field, index) in imageProcesses"
                    :key="field.value"
                    :class="{ 'vasion-highlighted' : field.value === selectedProcessID, 'vasion-lightest-grey-background': index % 2 === 0}"
                    @click="selectImageProcess(index, field.value)"
                  >
                    <td>
                      <label class="vasion-html-table-field">{{ field.name }}</label>
                    </td>
                    <td class="image-column-end">
                      <VasionButton
                        name="add-complete-email-template-button"
                        :icon="'VasionRemoveIcon'"
                        title="Remove Image Process"
                        class="configure-template-icon-button"
                        @vasionButtonClicked="removeImageProcess(field.value)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="summarySectionVisible" class="summary-section">
            <VasionCheckbox
              id="create-summary-document"
              name="create-summary-document"
              class="create-summary-document"
              :checked="createSummaryDocumentFlag"
              @change="changeSummaryDocumentFlag"
            >
              Create Summary Document
            </VasionCheckbox>
            <div v-if="createSummaryDocumentFlag" class="fields-grid">
              <VasionDropList
                v-slot="slotItem"
                v-model="summaryField"
                class=""
                :dataArray="filteredIndexFieldList"
                width="100%"
                title="Field to store the summary"
                placeholder="Select the field to store the summary..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
              <VasionDropList
                v-slot="slotItem"
                v-model="documentTemplate"
                class=""
                :dataArray="documentTemplates"
                :showIfEmpty="true"
                width="100%"
                title="Document Template"
                placeholder="Select the document template...."
                type="plain-list"
                displayName="name"
                valueName="documentTemplateID"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <p v-if="createSummaryDocumentFlag">*The summary will be limited to a maximum length of 4000 characters, aligning with the field limits.</p>
            <p v-if="createSummaryDocumentFlag">*To create the summary document, we will employ the document template.</p>
          </div>
        </div>
      </div>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <div class="folder-dialog-browse">
        <VasionFolders />
      </div>
      <div class="align-right">
        <VasionButton
          id="browse-cancel"
          classProp="secondary"
          @vasionButtonClicked="toggleBrowseFolderDialog()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="browse-ok"
          classProp="primary"
          @vasionButtonClicked="folderDialogOK()"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarText"
      :snackbarTitle="snackbarTitle"
    />
    
    <VasionAsynchronousConfirmDialog ref="confirmAipTypeChange" confirmText="Yes" cancelText="No" />

    </div>
  </div>
  
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import VasionAsynchronousConfirmDialog from '@/components/shared/VasionAsynchronousConfirmDialog.vue'

export default {
  name: 'EditScheduledAIP',
  components: {
    AutomationEngineSharedHeader,
    Loading,
    VasionAsynchronousConfirmDialog,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      aipType: '',
      canAddThisImageProcess: false,
      cohereToken: false,
      createSummaryDocumentFlag: false,
      documentTemplate: '',
      failureFolderId: 0,
      failureFolderName: '',
      fileBrowseField: '',
      imageProcess: {},
      imageProcesses: [],
      includeSubfolders: false,
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      onSuccessAction: 'none',
      processFolderId: 0,
      processFolderName: '',
      schedulerTitle: '',
      selectedProcessID: -1,
      showBuildAIP: true,
      showBrowseFoldersDialog: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbar: false,
      showTextract: false,
      snackbarImage: false,
      snackbarTitle: '',
      snackbarText: '',
      successFolderId: 0,
      successFolderName: '',
      summaryField: '',
      summarySectionVisible: false,
      textractData: {},
      typeBasic: 'vasion aip',
      typeTextract: 'amazon textract',
      workflow: {},
      workflowData: [],
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    documentTemplates() {
      return this.$store.state.common.documentTemplates.filter(docTemplate => {
        return this.$store.state.attributeForm.activeIndexForm.name === docTemplate.objectName
      })
    },
    filteredIndexFieldList() {
      const fieldNamesToIgnore = ['Workflow_Status', 'Workflow_Approver', 'Workflow_Due_Date', 'Workflow_Initiator', 'CreatedBy']
      if (!this.indexFieldList) return []
      return this.indexFieldList.map( field => {
        if ((field.type === 1 || field.type === 13) && !fieldNamesToIgnore.includes(field.name)) {
          return {
              name: field.title,
              value: field.name,
              fieldID: field.fieldID,
          }
        }
      }).filter(item => item)
    },
    imageProcessList() {
      if (!this.$store.state.capture.aipData.Values || this.$store.state.capture.aipData.Values.length === 0) {
        return []
      }
      return this.$store.state.capture.aipData.Values
        .filter(aip => aip.aipType.toLowerCase() == this.aipType)
        .map((aip) => {
          return {
              name: aip.procName,
              value: aip.aiAIPID,
          }
        })
    },
    indexFieldList() { return this.$store.state.attributeForm.activeIndexForm.fields },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    workflowList() {
      if (!this.workflowData || this.workflowData.length === 0) {
        return []
      }
      return this.workflowData.map((w) => {
          return {
              name: w.sName,
              value: w.iID,
          }
      })
    },
  },
  watch: {
    imageProcess: function () {
      if (Object.keys(this.imageProcess).length === 0) {
        this.canAddThisImageProcess = false
      } else {
        this.canAddThisImageProcess = !this.imageProcessFound(this.imageProcess.value)
      }
    },
    onSuccessAction: function () {
      if (this.onSuccessAction === 'none' || this.onSuccessAction === 'sendToFolder') {
        this.workflow = {}
      }
      if (this.onSuccessAction === 'none' || this.onSuccessAction === 'sendToWorkflow') {
        this.successFolderId = 0
        this.successFolderName = ''
        this.failureFolderId = 0
        this.failureFolderName = ''
      }
    },
  },
  async created() {
    this.isLoading = true
    this.aipType = this.typeBasic
    await this.$store.dispatch('automationEngine/clearActiveScheduler')
    const promises = await Promise.all([
      this.$store.dispatch('automationEngine/getMoveFieldComparisons'),
      this.$store.dispatch('capture/getAllAipProcs'),
      this.$store.dispatch('workflow/getWorkflowAdminList')
    ])
    // eslint-disable-next-line prefer-destructuring
    this.workflowData = promises[2]
    this.showTextract = this.$store.state.systemSettings.featureFlags.aipTextractEnable

    this.cohereToken = this.$store.state.systemSettings.featureFlags.cohereEnabled

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      const data = await this.$store.dispatch('automationEngine/getAipScheduler', parseInt(this.$route.params.schedulerId, 10))
      await this.setSchedulerValues(data)
      this.schedulerTitle = data.runDetails.name
      await this.setHeaderDetails(data.runDetails)
    } else {
      this.schedulerTitle = 'Untitled Scheduled AIP'
    }
    this.isLoading = false
  },
  mounted() {
    this.$root.confirmAipTypeChange = this.$refs.confirmAipTypeChange
  },
  methods: {
    addImageProcess() {
      if (this.aipType == 'amazon textract' && this.imageProcesses.length > 0) {
        this.imageProcesses = []
      }
      this.imageProcesses.push(
        {
          name: this.imageProcess.name,
          value: this.imageProcess.value,
        },
      )
      this.canAddThisImageProcess = false
      this.summarySectionVisible = false
      this.createSummaryDocumentFlag = false
      this.summaryField = ''
      this.documentTemplate = ''
      if (this.aipType === 'amazon textract') this.performSummaryValidations()
    },
    async aipTypeChanged() {
      let firstAipSelected = this.imageProcesses.length > 0 ? this.$store.state.capture.aipData.Values.find(ap => ap.aiAIPID == this.imageProcesses[0].value && ap.procName == this.imageProcesses[0].name ) : null
      if (this.aipType == firstAipSelected?.aipType?.toLowerCase()) {
        return
      }
      
      let res = !firstAipSelected
        || await this.$root.confirmAipTypeChange.open({ message: 'Are you sure you want to change the Configuration Type? This will remove all the added configurations.' })
      if (res) {
        this.imageProcesses = []
        this.imageProcess = {}
        this.isDirty = true
      } else {
        if (firstAipSelected) {
          this.aipType = firstAipSelected.aipType.toLowerCase()
        } else {
          this.aipType = this.aipType == this.typeTextract ? this.typeBasic : this.typeTextract
        }
      }

      if (this.aipType !== 'amazon textract') {
        this.summarySectionVisible = false
        this.createSummaryDocumentFlag = false
        this.summaryField = ''
        this.documentTemplate = ''
      }
    },
    cancel() { this.$router.push({ name: 'ScheduledAip' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    changeSummaryDocumentFlag() {
      this.isDirty = true
      this.createSummaryDocumentFlag = !this.createSummaryDocumentFlag
      if (this.createSummaryDocumentFlag) return

      this.summaryField = ''
      this.documentTemplate = ''
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ name: this.routeTo.name })
    },
    folderDialogOK() {
      if (this.selectedFolder
        && this.selectedFolder.isSelected === true && this.fileBrowseField !== '') {
        // Set folder info here, including ID and name
        if (this.fileBrowseField === 'processFolder') {
          this.isDirty = true
          this.processFolderId = this.selectedFolder.item.FolderId
          this.processFolderName = this.selectedFolder.item.Name
        } else if (this.fileBrowseField === 'successFolder') {
          this.isDirty = true
          this.successFolderId = this.selectedFolder.item.FolderId
          this.successFolderName = this.selectedFolder.item.Name
        } else {
          this.isDirty = true
          this.failureFolderId = this.selectedFolder.item.FolderId
          this.failureFolderName = this.selectedFolder.item.Name
        }
      } else {
        this.processFolderId = null
        this.processFolderName = ''
      }

      this.toggleBrowseFolderDialog()
    },
    hideSnackbar() { this.showSnackbar = false },
    imageProcessFound(id) {
        let isFound = false
        this.imageProcesses.forEach(e => {
            if (e.value === id) {
                isFound = true
            }
        })
        return isFound
    },
    moveImageProcess(direction) {
      if (this.selectedProcessID > 0) {
        let foundIndex = -1
        this.imageProcesses.forEach((e, index) => {
          if (e.value === this.selectedProcessID) {
              foundIndex = index
          }
        })
        if (foundIndex >= 0 && ((direction < 0 && foundIndex > 0) || (direction > 0 && foundIndex < this.imageProcesses.length))) {
          const f = this.imageProcesses.splice(foundIndex, 1)[0]
          this.imageProcesses.splice(foundIndex + direction, 0, f)
        }
      }
    },
    moveImageProcessDown() { this.moveImageProcess(1) },
    moveImageProcessUp() { this.moveImageProcess(-1) },
    async performSummaryValidations(fromInitialization = false) {
      if (!this.cohereToken) return

      if (!this.imageProcess?.value) return
      
      const response = await this.$store.dispatch('admin/getTextractAIP', this.imageProcess.value)
      if (!response || !response?.Data) return
      this.textractData = response.Data
      
      if (!this.textractData.Ocr || !this.textractData.IndexFormId)  return

      await this.$store.dispatch('attributeForm/getFormDetails', this.textractData.IndexFormId)
      if (this.filteredIndexFieldList.length < 1)  return
      
      await this.$store.dispatch('common/getDocumentTemplates')
      
      this.summarySectionVisible = true
      
      if (fromInitialization) return
      setTimeout(() => {
        const elementToScroll = document.getElementById("main-section")
        elementToScroll.scrollTo(0, elementToScroll.scrollHeight)
      }, 150);
    },
    removeImageProcess(id) {
      const isFound = this.imageProcessFound(id)
      this.canAddThisImageProcess = isFound

      if (this.aipType === 'amazon textract') { 
        this.summarySectionVisible = false
        this.createSummaryDocumentFlag = false
        this.summaryField = ''
        this.documentTemplate = ''
      }

      if (isFound) {
        this.selectedProcessID = -1
        this.imageProcesses = this.imageProcesses.filter(e => {
          return e.value !== id
        })
      }
    },
    async save() {
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          processFolderID: this.processFolderId,
          includeSubfolders: this.includeSubfolders,
          successFolderID: (this.successFolderId > 0) ? this.successFolderId : null,
          failFolderID: (this.failureFolderId > 0) ? this.failureFolderId : null,
          successWFID: this.workflow ? this.workflow.value : null,
          orderedAIPIDs: this.imageProcesses.map((ip) => { return ip.value }),
          createSummaryDocument: this.createSummaryDocumentFlag,
          summaryFieldID: this.summaryField?.fieldID ? this.summaryField.fieldID : null,
          templateDocID: this.documentTemplate?.documentTemplateID ? this.documentTemplate.documentTemplateID : null,
          textractId: this.aipType === 'amazon textract' && this.imageProcess?.value ? this.imageProcess.value : null,
        }
        if (this.aipType === 'amazon textract' && this.imageProcesses.length > 0 ) {
          payload.textractId = this.imageProcesses[0].value
        }
        if (!await this.$store.dispatch('automationEngine/saveAIPScheduler', payload)) {
          const errorText = 'There was an error saving the AIP.'
          this.snackbarTitle = 'ERROR'
          this.snackbarText = errorText
          this.showSnackbar = true
          this.snackbarImage = false
          console.warn(errorText)
        } else {
          this.snackbarTitle = 'SUCCESS'
          this.snackbarText = 'AIP saved successfully'
          this.showSnackbar = true
          this.snackbarImage = true
          this.isDirty = false
          setTimeout(() => { this.$router.push({ name: 'ScheduledAip' }) }, 1500)
        }
      }
    },
    selectImageProcess(row, value) { this.selectedProcessID = value },
    setHeaderDetails(details) {
      const { header } = this.$refs
      header.setValues(details)
    },
    async setSchedulerValues(savedData) {
      this.processFolderId = savedData.processFolderID
      this.processFolderName = savedData.processFolderName
      this.includeSubfolders = savedData.includeSubfolders
      this.successFolderId = savedData.successFolderID
      this.successFolderName = savedData.successFolderName
      this.failureFolderId = savedData.failFolderID
      this.failureFolderName = savedData.failFolderName
      this.workflow = this.workflowList.find(workflow => { return workflow.value === savedData.successWFID })
      if (this.successFolderName && this.failureFolderName) {
        this.onSuccessAction = 'sendToFolder'
      } else if (this.workflow) {
        this.onSuccessAction = 'sendToWorkflow'
      }
      if (savedData.textractId > 0) {
        this.aipType = this.typeTextract
        if(!await this.$store.dispatch('common/getIsTextractLicensed')) {
          this.imageProcesses = []
        } else {
          //NOTE:: we are only searching by ID here, which is OK, because we have already filtered the imageProcessList by type.
          // otherwise, we could end up with a conflict as Vasion AIP(Basic AIP) and Textract AIPs could have the same IDs
          this.imageProcesses = [this.imageProcessList.find(imageProcess => imageProcess.value === savedData.textractId)]
        }
      } else {
        this.aipType = this.typeBasic
        this.imageProcesses = savedData.orderedAIPIDs.map(id => { return this.imageProcessList.find(imageProcess => imageProcess.value === id) })
      }

      this.imageProcess = this.imageProcesses[0]
      await this.performSummaryValidations(true)
      this.createSummaryDocumentFlag = savedData.createSummaryDocument
      this.summaryField = this.filteredIndexFieldList.find(field => { return field.fieldID === savedData.summaryFieldID })
      this.documentTemplate = this.documentTemplates.find(docTemplate => { return docTemplate.documentTemplateID === savedData.templateDocID })
    },
    toggleBrowseFolderDialog(field) {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(id) { this[id] = !this[id] },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = 'ERROR: '

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText = `${errorText} ${header.errors}`
      }
      // Any other validation for this component goes here ...
      if (this.imageProcesses.length === 0) {
          error = true
          errorText = 'You must have one or more image processes in the list'
      }
      if (this.onSuccessAction === 'sendToFolder' && (this.successFolderName === '' || this.failureFolderName === '')) {
          error = true
          errorText = 'Please select success and failure folders'
      }
      if (this.onSuccessAction === 'sendToWorkflow' && !this.workflow) {
          error = true
          errorText = 'Please select a workflow'
      }
      if (this.processFolderName === '') {
          error = true
          errorText = 'Please select a process folder'
      }
      if (this.createSummaryDocumentFlag && !this.documentTemplate?.documentTemplateID) {
        error = true
        errorText = 'Please select the document template'
      }
      if (this.createSummaryDocumentFlag && !this.summaryField?.fieldID) {
        error = true
        errorText = 'Please select a field to store the summary'
      }
      
      if (error) {
        this.snackbarTitle = 'ERROR'
        this.snackbarText = errorText
        this.showSnackbar = true
        this.snackbarImage = false
        console.warn(errorText)
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #scheduled-aip-details {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .section-title {
        @include SECTION-HEADER;
        color: $grey-400;
        display: block;
        margin: 5px 0;
      }

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;

        &.checkbox {
          width: 390px;
        }
      }

      .browse-input {
        margin-right: 5px;
      }

      .vasion-button {
        &.browse-button {
          button {
            margin-top: 22px;
            margin-left: 0;
            margin-right: 13px;
          }
        }
      }

      .scheduler-checkbox {
        width: 408px;
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 100%;

      .subheader {
        @include SubHeadline;
        width: 225px;
        display: block;
        float: left;
        margin-top: 5px;
      }

      .subtitle {
        @include Subtitle;
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }
  .align-values {
      display: flex;
      flex-direction: row;
      align-items: center;
  }

  .image-column-end {
    max-width: 100px;
    width: 100px;
    fill: $grey-400;
    padding-right: 15px;
  }
  .table-width {
      width: 100%;
      margin-right:15px;
  }
  .radio-buttons {
    margin-top: -15px;
  }
  .export-checkbox {
    padding: 26px;
  }
  .arrow-buttons {
     margin-top: 30px;
     margin-right: 20px;
     width: 100%;
     text-align: right;
  }
  .vasion-highlighted {
    background-color: $orange-50;
  }
  .summary-section {
    min-height: 160px;
  }
  .create-summary-document {
    display: flex;
  }
  .fields-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .summary-section p {
    margin-bottom: 0px;
  }
  .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
